// api.js

const API_URL = 'https://admin.smoochy.uk/api'; // Replace with your API URL

const login = async (email, password) => {
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'mode': 'no-cors'
    };

    const res = await fetch(API_URL + "/login", { method: 'POST', headers: nativeHeaders, body: JSON.stringify({ email, password }) });
    const finalRes = await res.json();

    if (finalRes.token) {
        // Store the token in localStorage to keep the user logged in
        localStorage.setItem('authToken', finalRes.token);
    }

    return finalRes;
}

const register = async (payload) => {
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'mode': 'no-cors'
    };
    const res = await fetch(API_URL + "/register", { method: 'POST', headers: nativeHeaders, body: JSON.stringify(payload) });
    const finalRes = await res.json();
    if (finalRes.token) {
        // Store the token in localStorage to keep the user logged in
        localStorage.setItem('authToken', finalRes.token);
    }
    return finalRes;
}

const forget = async (email) => {
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'mode': 'no-cors'
    };
    const res = await fetch(API_URL + "/forget-Password", { method: 'POST', headers: nativeHeaders, body: JSON.stringify({ email }) });
    const finalRes = await res.json();
    return finalRes;
}

const contact = async (name, email, subject, message) => {
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'mode': 'no-cors'
    };
    const res = await fetch(API_URL + "/contact", { method: 'POST', headers: nativeHeaders, body: JSON.stringify({ name, email, subject, message }) });
    const finalRes = await res.json();
    return finalRes;
}

const getToken = () => {
    return localStorage.getItem('authToken');
}

const postData = async (route, payload) => {
    const token = getToken();
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'mode': 'no-cors'
    };

    if (token) {
        nativeHeaders['Authorization'] = `Bearer ${token}`;
    }

    const res = await fetch(API_URL + route, { method: 'POST', headers: nativeHeaders, body: JSON.stringify(payload) });
    const finalRes = await res.json();
    return finalRes;
}

const postFormData = async (route, payload) => {
    const token = getToken();
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'mode': 'no-cors'
    };

    if (token) {
        nativeHeaders['Authorization'] = `Bearer ${token}`;
    }

    const res = await fetch(API_URL + route, { method: 'POST', headers: nativeHeaders, body: JSON.stringify(payload) });
    const finalRes = await res.json();
    return finalRes;
}

const getData = async (route) => {
    const token = getToken();
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };

    if (token) {
        nativeHeaders['Authorization'] = `Bearer ${token}`;
    }

    const res = await fetch(API_URL + route, { method: 'GET', headers: nativeHeaders });
    const finalRes = await res.json();
    return finalRes;
}

const initiateIDScan = async (email) => {
    const token = getToken(); // Optional: Add token if needed for authorization
    let nativeHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    };

    if (token) {
        nativeHeaders['Authorization'] = `Bearer ${token}`;
    }

    const res = await fetch(API_URL + "/agechecked-id-scan", { 
        method: 'POST', 
        headers: nativeHeaders, 
        body: JSON.stringify({ 
            email, 
            callbackUrl: API_URL + '/api/id-scan-callback'
        })
    });
    const finalRes = await res.json();
    return finalRes;
};


const checkIDScanStatus = async (agecheckid) => {
    const token = getToken(); // Optional: Add token if needed for authorization
    let nativeHeaders = {
        'Content-Type': 'application/json',
    };

    if (token) {
        nativeHeaders['Authorization'] = `Bearer ${token}`;
    }

    try {
        const res = await fetch(`${API_URL}/id-scan-status`, { 
            method: 'POST', 
            headers: nativeHeaders,
            body: JSON.stringify({ agecheckid: agecheckid})
        });

        if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
        }

        const finalRes = await res.json();
        return finalRes;
    } catch (error) {
        console.error('Error checking ID scan status', error);
        throw error; // Rethrow to handle upstream
    }
};

export { login, register, forget, contact, postData, postFormData, getData, initiateIDScan, checkIDScanStatus};
